import React, { useEffect, useState } from 'react';

import {

    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import { BanknotesIcon, ChatBubbleLeftRightIcon, CheckCircleIcon, ClockIcon, DocumentMagnifyingGlassIcon, ShieldCheckIcon, XCircleIcon } from '@heroicons/react/24/outline';
import MelbetHistory from './MelbetHistory';
import Chat from '../Chat';
import LiveBets from '../LiveBets';
import CrashStatisticsResults from '../CrashStatisticsResults';
import MelbetLiveBets from './MelbetLiveBets';







export default function MelbetTables() {

    const hideMyBetsSection = JSON.parse(localStorage.getItem('hideMyBetsSection'));



    return (
        <div className="bg-[#151937] rounded-xl md:max-h-[300px]">

            <Tabs className="" id="custom-animation-mel" value="live">

                <TabsHeader
                    //  className="h-10 -mb-1 bg-gradient-to-r from-[#f7a026] to-[#c83c48] rounded-t-xl rounded-b-none font-medium"
                    // className="h-10 -mb-1 bg-gradient-to-r from-[#49c92c] to-[#0652dd] rounded-t-xl rounded-b-none font-medium"
                    //   className="h-10 -mb-1 bg-gradient-to-r from-[#f7a026] to-[#0652dd] rounded-t-xl rounded-b-none font-medium"
                    //  className="h-10 -mb-1 bg-gradient-to-r from-[#FF4500] to-[#6b1d00] rounded-t-xl rounded-b-none font-medium"
                    //    className="h-10 -mb-1 bg-gradient-to-r from-[#f7a026] to-[#49c92c] rounded-t-xl rounded-b-none font-medium"
                    //  className="h-10 -mb-1 bg-gradient-to-r from-[#eb5ccf] to-[#642269] rounded-t-xl rounded-b-none font-medium"
                    className="h-10 -mb-1 bg-gradient-to-r from-[#d25c18] to-[#c83c48] rounded-t-xl rounded-b-none font-medium"



                    indicatorProps={{
                        className: "rounded-sm bg-[#2e335c] shadow-none",
                    }}
                >
                    <Tab key="live" value="live" className="text-gray-200 text-xs uppercase flex ">
                        <span className='flex flex-cols items-center justify-center gap-1'>
                            <span><BanknotesIcon className="h-4 w-4 text-white" strokeWidth={2.0} /></span>
                            <span className="hidden md:flex">Live Bets</span>
                        </span>
                    </Tab>
                    {hideMyBetsSection === false &&
                        <Tab key="history" value="history" className="text-gray-200  text-xs uppercase w-full rounded">
                            <span className='flex flex-cols items-center justify-center gap-1'>
                                <span><ClockIcon className="h-4 w-4 text-white" strokeWidth={2.0} /></span>
                                <span className="hidden md:flex">My Bets</span>
                            </span>
                        </Tab>
                    }
                    <Tab key="chat" value="chat" className="text-gray-200 text-xs uppercase">
                        <span className='flex flex-cols items-center justify-center gap-1'>
                            <span><ChatBubbleLeftRightIcon className="h-4 w-4 text-white" strokeWidth={2.0} /></span>
                            <span className="hidden md:flex">Chat</span>
                        </span>
                    </Tab>
                    <Tab key="crash" value="crash" className="text-gray-200 text-xs uppercase">
                        <span className='flex flex-cols items-center justify-center gap-1'>
                            <span><ShieldCheckIcon className="h-4 w-4 text-white" strokeWidth={2.0} /></span>
                            <span className="hidden md:flex">Crash Points</span>
                        </span>
                    </Tab>

                </TabsHeader>

                <TabsBody className=''
                    animate={{
                        initial: { y: 250 },
                        mount: { y: 0 },
                        unmount: { y: 250 },
                    }}
                >
                    <TabPanel className="m-0 p-0" key="live" value="live">
                        <MelbetLiveBets
                        />

                    </TabPanel>

                    {hideMyBetsSection === false &&
                        <TabPanel className="m-0 p-1" key="history" value="history">
                            <MelbetHistory

                            />
                        </TabPanel>
                    }
                    <TabPanel className="m-0 p-1" key="chat" value="chat">
                        <Chat

                        />


                    </TabPanel>
                    <TabPanel className="m-0 p-1" key="crash" value="crash">
                        <CrashStatisticsResults
                        />

                    </TabPanel>


                </TabsBody>

            </Tabs>



        </div>

    )
}