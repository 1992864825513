import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pusher from 'pusher-js';
import Moment from 'react-moment';
import { setLiveBets } from '../../features/liveBetsSlice';


import ShieldImage from '../../assets/images/shield.png';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Typography,
    Input,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { ClockIcon } from '@heroicons/react/24/solid';
import { BanknotesIcon, CheckCircleIcon, CurrencyDollarIcon, DocumentMagnifyingGlassIcon, UserIcon, XCircleIcon } from '@heroicons/react/24/outline';




export default function MelbetLiveBets() {

    const dispatch = useDispatch();
    const liveBets = useSelector((state) => state.liveBets.value);


    const authentication = useSelector((state) => state.authentication.value);
    const firstBanner = JSON.parse(localStorage.getItem('firstBanner'));
    const secondBanner = JSON.parse(localStorage.getItem('secondBanner'));



    const [websocketsDomain, setWebsocketsDomain] = useState(window.WEBSOCKETS_DOMAIN);
    const [websocketsConnection, setWebsocketsConnection] = useState(window.WEBSOCKETS_CONNECTION);
    const [pusherAppKey, setPusherAppKey] = useState(window.PUSHER_APP_KEY);
    const LIVE_BETS_TABLE_HEAD = ["USERNAME", "ODDS", "BET", "WIN"];
    const [totalBetAmount, setTotalBetAmount] = useState(0);
    const [totalWinningsAmount, setTotalWinningsAmount] = useState(0);
    const [hideNumberOfPlayers, setHideNumberOfPlayers] = useState(false);
    const [hideTotalAmount, setHideTotalAmount] = useState(false);
    const [hideTotalWinnings, setHideTotalWinnings] = useState(false);
    const [topDelay, setTopDelay] = useState(null);


    const hideMyBetsSection = JSON.parse(localStorage.getItem('hideMyBetsSection'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));





    const formatCoefficient = (coefficient) => {
        if (coefficient !== null) {
            var text1 = "x";
            var num = parseFloat(coefficient).toFixed(2);
            let result = text1.concat(num);
            return result;
        }
        else {
            return coefficient;
        }
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }






    useEffect(() => {

        if (websocketsConnection === 'self_hosted') {
            //self hosted websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 443,
                forceTLS: true,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 443,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else if (websocketsConnection === 'local') {
            //local server websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 6001,
                forceTLS: false,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 6001,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else {
            // paid pusher configuration
            var pusher = new Pusher(pusherAppKey, {
                cluster: 'eu'
            });
        }

        const fetchData = async () => {

            const channel = pusher.subscribe('LiveBetsChannel');
            channel.bind('LiveBetsEvent', function (data) {

                //  console.log(data.liveBets);

                dispatch(setLiveBets(data.liveBets));


                var sum = data.liveBets.reduce((accumulator, object) => {
                    return accumulator + object.amount;
                }, 0);

                setTotalBetAmount(() => sum);

                var sumW = data.liveBets.reduce((accumulator, object) => {
                    return accumulator + object.win_amount;
                }, 0);

                setTotalWinningsAmount(() => sumW);

            })

            const topDelayChannel = pusher.subscribe('topDelayChannel');
            topDelayChannel.bind('topDelayEvent', function (event) {

                var str = event.topDelay;
                str = str.replace("data: ", "");
                var obj = [];
                str = str.replace(/'/g, '"');
                obj = JSON.parse(str);


                if (obj.topDelay == 'false') {
                    setTopDelay((delay) => null);
                } else {
                    setTopDelay((delay) => obj.topDelay);
                }

            })

        }
        fetchData();
    }, []);






    return (
        <div className={'h-[320px] pt-0 mt-0 pb-14 '
            + ((firstBanner == false && authentication == false) ? 'md:h-[440px]' : '')
            + ((firstBanner == true && authentication == false) ? 'md:h-[580px]' : '')
            + ((secondBanner == false && authentication == true) ? 'md:h-[430px]' : '')
            + ((secondBanner == true && authentication == true) ? 'md:h-[580px]' : '')
        }>
            {/* <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#f7a026] to-[#c83c48] rounded-none  text-xs text-gray-50 w-full">  */}
            {/* <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#49c92c] to-[#0652dd] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#f7a026] to-[#0652dd] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#FF4500] to-[#6b1d00] rounded-none  text-xs text-gray-50 w-full"> */}
            {/* <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#f7a026] to-[#49c92c] rounded-none  text-xs text-gray-50 w-full">  */}
            {/* <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#eb5ccf] to-[#642269] rounded-none  text-xs text-gray-50 w-full"> */}
            <div className="flex items-center mt-0 p-0 md:p-2 h-14 md:h-10 bg-gradient-to-r from-[#d25c18] to-[#c83c48] rounded-none  text-xs text-gray-50 w-full">

                <div className='grid grid-cols-3 gap-2 w-full'>
                    {hideNumberOfPlayers == false &&
                        <div className="font-medium">
                            <span className='flex items-center justify-center text-center'>No. of Players</span>
                            <span className='flex flex-cols items-center justify-center gap-1'><UserIcon className="h-3 w-3 text-white" strokeWidth={3.0} />{(liveBets.length * 3)}</span>
                        </div>
                    }
                    {hideTotalAmount == false &&
                        <div className="font-medium">
                            <span className='flex items-center justify-center text-center'>Total Amount</span>
                            <span className='flex flex-cols items-center justify-center gap-1'><BanknotesIcon className="h-3 w-3 text-white" strokeWidth={3.0} />{formatAmount(totalBetAmount)}</span>
                        </div>
                    }
                    {hideTotalWinnings == false &&
                        <div className="font-medium">
                            <span className='flex items-center justify-center text-center'>Total Winnings</span>
                            <span className='flex flex-cols items-center justify-center gap-1'><BanknotesIcon className="h-3 w-3 text-white" strokeWidth={3.0} />{formatAmount(totalWinningsAmount)}</span>
                        </div>
                    }
                </div>
            </div>
            <Card className="p-1 md:p-0 w-full h-full rounded-none bg-[#181c3a]  overflow-y-scroll">

                <table className="w-full min-w-max table-auto text-left bg-[#181c3a]">
                    <thead>
                        <tr>
                            {LIVE_BETS_TABLE_HEAD.map((head) => (
                                <th key={head} className="border-b bg-[#181c3a] p-2 text-gray-400">
                                    <Typography
                                        variant="small"
                                        color="inherit"
                                        className="text-xs leading-none font-medium"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {liveBets.map(({ id, user_id, cashout, amount, win_amount, username }, index) => {
                            const classes = "px-1 py-1 bg-[#181c3a] border-b-2 border-[#2e335c] ";

                            return (

                                <tr key={id} className={"rounded-md " + ((win_amount !== null) ? " text-[#8fc92c]" : " ") + ((win_amount === null && topDelay !== null && topDelay >= 7) ? "  text-[#ff292c]" : " ") + ((win_amount === null && (topDelay === null || topDelay < 8)) ? "  text-gray-400" : " ")}>
                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium ">
                                            {username}
                                        </Typography>

                                    </td>

                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                            {(cashout !== null) &&
                                                formatCoefficient(cashout)
                                            }
                                            {(cashout == null) &&
                                                <>___</>
                                            }
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                            {formatAmount(amount)}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="inherit" className="text-xs font-medium">
                                            {(win_amount !== null) &&
                                                formatAmount(win_amount)
                                            }
                                            {(win_amount == null) &&
                                                <>___</>
                                            }
                                        </Typography>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>

            </Card>

        </div>
    )
}
